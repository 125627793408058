<script setup lang="ts">
import { computed, ref } from 'vue'
import CardKeyContact from '~/components/KeyContact/CardKeyContact.vue'

interface Props {
    pageData: any
}

const props = defineProps<Props>()
const route = useRoute()
const { t } = useI18n()
const countryName = computed(
    () =>
        t('bestFriendIntroText', {
            country: props.pageData?.country?.translations[0]?.name
        }) || ''
)

const mapKeyContactToEmployee = (items: any) => {
    if (!Array.isArray(items)) return []

    return items.map(kc => ({ ...kc.keyContact }))
}
const keyContacts = ref(
    mapKeyContactToEmployee(props.pageData?.country?.keyContacts)
)

watch(
    () => props.pageData,
    () => {
        keyContacts.value = mapKeyContactToEmployee(
            props.pageData?.country?.keyContacts
        )
    },
    { deep: true, immediate: true }
)
const disclaimerText = computed(() =>
    props.pageData?.country?.translations?.length
        ? props.pageData.country.translations[0].text
        : ''
)

const offices = computed(() => props.pageData.offices)
</script>

<template>
    <div v-if="pageData" class="best-friend">
        <div class="teams-page__hero">
            <div class="container">
                <h1>{{ countryName }}</h1>
            </div>
            <img
                :src="pageData?.country?.imageLink"
                :alt="countryName"
                class="teams-page__hero-bg"
            />
        </div>
        <div class="container">
            <div id="breadcrumbs" class="breadcrumbs">
                <AppNuxtLinkLocale :to="{ path: '/' }"
                    >{{ $t('home') }}
                </AppNuxtLinkLocale>
                <AppNuxtLinkLocale
                    :to="{
                        path: `/${route.params.countryslug}/regions`
                    }"
                >
                    {{ countryName }}
                </AppNuxtLinkLocale>
            </div>

            <div class="disclaimer">
                <h6 class="disclaimer__title">
                    {{ $t('footerSubMenu.disclaimer') }}
                </h6>
                <div
                    class="disclaimer__text"
                    v-html="disclaimerText"
                ></div>
            </div>
            <div class="best-friend__content">
                <div class="best-friend__keycontacts">
                    <CardKeyContact
                        v-for="(employee, index) in keyContacts"
                        :key="index"
                        :key-contact="employee"
                    />
                </div>

                <div class="best-friend__offices">
                    <div
                        v-for="office in offices"
                        :key="office.id"
                        class="best-friend__office"
                    >
                        <div class="best-friend__office-main">
                            <div class="best-friend__office-logo">
                                <img :src="office.imageLink" />
                            </div>
                            <div class="best-friend__office-name">
                                {{ office.translations[0].name }}
                            </div>
                            <div
                                v-html="office.translations[0].text"
                            ></div>
                            <!--                            <div class="best-friend__office-position">-->
                            <!--                                {{-->
                            <!--                                    office.keyContacts?.length-->
                            <!--                                        ? office.keyContacts[0]-->
                            <!--                                              .keyContact-->
                            <!--                                              .translations[0]-->
                            <!--                                              .jobTitle-->
                            <!--                                        : 'no job title'-->
                            <!--                                }}-->
                            <!--                            </div>-->
                            <!--                            <div class="best-friend__office-employee">-->
                            <!--                                {{-->
                            <!--                                    office.keyContacts?.length-->
                            <!--                                        ? office.keyContacts[0]-->
                            <!--                                              .keyContact-->
                            <!--                                              .translations[0].name-->
                            <!--                                        : 'no job name'-->
                            <!--                                }}-->
                            <!--                            </div>-->
                        </div>
                        <div class="best-friend__office-actions">
                            <a
                                :href="
                                    office.translations[0]
                                        .description ||
                                    'https://gratanet.com'
                                "
                                target="_blank"
                                >{{ $t('goToWebSite') }}
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M13.0003 18.7469C12.9018 18.7474 12.8041 18.7282 12.7131 18.6904C12.6221 18.6526 12.5396 18.597 12.4703 18.5269C12.3299 18.3863 12.251 18.1957 12.251 17.9969C12.251 17.7982 12.3299 17.6075 12.4703 17.4669L17.9403 11.9969L12.4703 6.52692C12.3378 6.38474 12.2657 6.1967 12.2691 6.00239C12.2726 5.80809 12.3513 5.62271 12.4887 5.48529C12.6261 5.34788 12.8115 5.26917 13.0058 5.26574C13.2001 5.26231 13.3881 5.33444 13.5303 5.46692L19.5303 11.4669C19.6708 11.6075 19.7497 11.7982 19.7497 11.9969C19.7497 12.1957 19.6708 12.3863 19.5303 12.5269L13.5303 18.5269C13.4611 18.597 13.3785 18.6526 13.2875 18.6904C13.1965 18.7282 13.0989 18.7474 13.0003 18.7469Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M19 12.75H5C4.80109 12.75 4.61032 12.671 4.46967 12.5303C4.32902 12.3897 4.25 12.1989 4.25 12C4.25 11.8011 4.32902 11.6103 4.46967 11.4697C4.61032 11.329 4.80109 11.25 5 11.25H19C19.1989 11.25 19.3897 11.329 19.5303 11.4697C19.671 11.6103 19.75 11.8011 19.75 12C19.75 12.1989 19.671 12.3897 19.5303 12.5303C19.3897 12.671 19.1989 12.75 19 12.75Z"
                                        fill="white"
                                    />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.best-friend {
    line-height: 2;

    &__keycontacts {
        max-width: 280px;
        min-width: 280px;
        @include md-block() {
            max-width: 100%;

            .person-page__card {
                margin-bottom: 16px;
            }
        }
    }

    .person-card__number {
        font-size: 18px !important;
        color: #5a5a5a;
    }

    &__content {
        display: flex;
        gap: 45px;
        @include sm-block() {
            flex-direction: column;
            align-items: center;
            .person-page__card {
                flex-direction: column;
            }

            .person-card__img {
                max-width: 100%;
            }
        }
    }

    &__offices {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        @include sm-block() {
            flex-direction: column;
            margin-bottom: 16px;
        }
    }

    &__office {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.3);
        border-radius: 6px;
        min-height: 535px;
        max-height: 535px;
        padding: 27px 32px;
        width: 100%;
        max-width: 400px;
        min-width: 250px;

        &-logo {
            img {
                height: 100px;
                width: 320px;
                object-fit: contain;
            }
        }

        &-name {
            color: #f54305;
            font-size: 20px;
        }

        &-position,
        &-employee {
            color: #000;
        }
    }

    &__office-actions {
        a {
            padding: 8px 16px;
            background-color: #3b82f6;
            color: #fff;
            border-radius: 65px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            align-self: flex-start;
            max-width: 175px;
        }
    }
}

.disclaimer {
    margin-bottom: 24px;
    background-color: rgba(217, 217, 217, 0.3);
    padding: 4px 28px;

    &__title {
        font-weight: bold;
        color: #2a2a2a;
    }
}
</style>
